import { useAuthentication } from "@tecnobit-srl/authentication";
import { useMemo } from "react";
const useFormPrefillValues = (id, {
  defaultValue,
  defaultLabel,
  defaultNumber,
  defaultPrefixLabel,
  defaultPrefixValue
} = {}) => {
  const { user } = useAuthentication();
  return useMemo(() => {
    var _a, _b, _c, _d, _e, _f, _g;
    const res = {
      defaultValue,
      defaultLabel,
      defaultNumber,
      defaultPrefixLabel,
      defaultPrefixValue
    };
    const userValue = user && id && user[id] ? user[id] : null;
    if (!userValue) {
      return res;
    }
    if (user) {
      switch (id) {
        case "phone":
        case "mobilephone":
          {
            const extractedUserPhone = /^(?<prefix>\+\d+)\s+?(?<number>\d+)$/.exec(user[id] ?? "");
            if (extractedUserPhone) {
              res.defaultPrefixLabel = user[`${id}PrefixLabel`] ?? ((_a = extractedUserPhone.groups) == null ? void 0 : _a.prefix) ?? "🇮🇹 +39";
              res.defaultPrefixValue = ((_b = extractedUserPhone.groups) == null ? void 0 : _b.prefix) ?? "+39";
              if ((_c = extractedUserPhone.groups) == null ? void 0 : _c.number) {
                res.defaultNumber = extractedUserPhone.groups.number;
              }
            }
          }
          break;
        case "country":
          res.defaultLabel = user.country.name;
          res.defaultValue = user.country.code;
          break;
        case "state":
          res.defaultLabel = user.state.name;
          res.defaultValue = user.state.code;
          break;
        case "sector":
          res.defaultLabel = (_d = user.sector) == null ? void 0 : _d.name;
          res.defaultValue = (_e = user.sector) == null ? void 0 : _e.id;
          break;
        case "category":
          res.defaultLabel = (_f = user.category) == null ? void 0 : _f.name;
          res.defaultValue = (_g = user.category) == null ? void 0 : _g.id;
          break;
        default:
          res.defaultLabel = userValue;
          res.defaultValue = userValue;
      }
    }
    return res;
  }, [
    defaultLabel,
    defaultNumber,
    defaultPrefixLabel,
    defaultPrefixValue,
    defaultValue,
    id,
    user
  ]);
};
export {
  useFormPrefillValues
};
